 <template>
  <div class="container">
    <h3>发现企业</h3>
    <div class="lists" v-show="show">
      <div class="analysis">
        <div class="card">
          <div class="cardContent">您可以选取或者创建一些招商条件，对企业库中的需求进行筛选，发现更多优质企业。</div>
          <div class="cardFooter">
              <el-button type="primary" style="width: 200px;" @click="newFind()">创建招商条件</el-button>
          </div>
        </div>
      </div>
      <div class="history" v-if="tableData.length!==0">
        <p class="demo">以下是您的历史招商需求，大数据实时为您分析推荐优秀项目</p>
        <el-radio-group v-model="radio" class="radio" @change="getRadio()">
          <el-radio :label="3">全部</el-radio>
          <el-radio :label="6">正在追踪</el-radio>
          <el-radio :label="9">暂停追踪</el-radio>
        </el-radio-group>
        <el-table
        :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%;margin-top:30px">
          <el-table-column  align="center"
            label="招商需求"
            prop="title">
          </el-table-column>
          <el-table-column  align="center"
            label="招商需求描述"
            prop="intro">
          </el-table-column>
          <el-table-column  align="center"
            label="推荐企业项目"
            prop="project_count">
          </el-table-column>
          <el-table-column prop="new_count"  align="center">
            <template slot="header">
                <el-select v-model="value" placeholder="本月新增">
                <el-option
                  v-for="item in optionindex"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  popper-class="select-option">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            prop="data">
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center" fixed="right" width="360">
            <template v-slot="scope">
              <el-button @click="look(scope.row)" type="primary" size="mini">查看</el-button>
              <el-button @click="jumpFind(scope.row)" type="primary" size="mini">编辑</el-button>
              <el-button :type="scope.row.status?'primary':'danger'" size="mini" @click="handleDelete(scope.row)">{{scope.row.status?'启用':'禁用'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pages" v-if="this.pageCount!==1">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-count="pageCount"
            layout="total,prev,pager,next,jumper"
            :total="pages"
            style="margin:20px auto;text-align: center;"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="first"  v-show="!show">
      <div class="demand">
        <el-steps :active="num" align-center v-if="num<5">
          <el-step title="描述招商需求"></el-step>
          <el-step title="设置招商条件"></el-step>
          <el-step title="条件清单"></el-step>
          <el-step title="设置权重"></el-step>
          <el-step title="结果展示"></el-step>
        </el-steps>
        <div class="name" v-if="num==1">
          <p>描述招商需求</p>
          <div class="ipt">
            <div class="text">
              <span>招商需求名称：</span>
              <el-input style="width:80%" v-model="ipt" maxlength="30"></el-input>
            </div>
            <div class="text">
              <span>招商需求描述：</span>
              <el-input type="textarea" style="width:80%" resize="none" :rows="6" v-model="text" maxlength="200"></el-input>
            </div>
          </div>
        </div>
        <!-- 创建条件第二步 -->
        <div class="second" v-if="num==2">
          <p>设置招商条件</p>
          <el-checkbox-group v-model="checkList" @change="businessConditionChange">
            <el-checkbox :label="condition.id" @change="change(index,condition.type)" v-for="(condition,index) in condition" :key="index">{{condition.name}}</el-checkbox>
             <el-button size="small" @click="undefineds=true" style="margin-left:15px">未定义</el-button>
             <el-dialog title="自定义添加" :visible.sync="undefineds">
              <el-form :model="form" style="width:80%; margin:auto">
                <div class="ipt">
                  <div class="text">
                    <span>指标名称：</span>
                    <el-input v-model="input" placeholder="请输入指标名称" style="width:60%" maxlength="20"></el-input>
                    <p class="warn">不超过20个字符</p>
                  </div>
                  <div class="text">
                    <span>指标定义：</span>
                    <el-input
                      type="textarea"
                      placeholder="请输入指标定义内容"
                      v-model="textarea1" resize="none" style="width:60%"  maxlength="1000" :rows="6">
                    </el-input>
                    <p class="warn">不超过1000个字符</p>
                  </div>
                  <div class="text">
                    <span>特色案例：</span>
                    <el-input
                      type="textarea"
                      placeholder="请输入特色案例内容"
                      v-model="textarea2" resize="none" style="width:60%" maxlength="1000" :rows="6">
                    </el-input>
                    <p class="warn">不超过1000个字符</p>
                  </div>
                </div>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="undefineds = false">取 消</el-button>
                <el-button type="primary" @click="sure()">确 定</el-button>
              </div>
            </el-dialog>
          </el-checkbox-group>
        </div>
        <!-- 创建条件第三步 -->
        <div class="three" v-if="num==3">
          <div class="threeTit">请确认所添加内容。若需取消，可在此去除勾选；若有缺失，请返回上一级页面，再次进行添加。</div>
          <div class="work" v-if="jobValues.length!==0">
            <span>行业：</span>
            <div class="selectwork">
              <el-checkbox-group v-model="jobValueChecked">
                <el-checkbox :label="job.id" v-for="(job,index) in jobValues" :key="index" :checked="checked">{{job}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="work" v-if="areasValue.length!==0">
            <span>地区：</span>
            <div class="selectwork">
              <el-checkbox :label="area" v-for="(area,index) in areasValue" :key="index"  :checked="checked"></el-checkbox>
            </div>
          </div>
          <div class="work" v-if="honers.length!==0">
            <span>企业荣誉：</span>
            <div class="selectwork">
              <el-checkbox :label="honer.title" v-for="(honer,index) in honers" :key="index"  :checked="checked"></el-checkbox>
            </div>
          </div>
          <div class="sort">
            <p v-if="size!==''">规模：<span>{{size}}</span></p>
            <p v-if="people!==''">从业人数：<span>{{people}}</span></p>
            <p v-if="money!==''">营收收入：<span>{{money}}</span>万元</p>
            <p v-if="total!==''"> 资产总额：<span>{{total}}</span>万元</p>
            <p v-if="selected!==''"> 融资轮次：<span>{{selected}}</span></p>
            <p v-if="grades!==''"> 所属级别：<span>{{grades}}</span></p>
            <p v-if="customize!=={}">{{customize.name}}:<span>{{customize.desc}}</span></p>
          </div>
        </div>
         <!--创建条件第四步  -->
        <div class="four" v-if="num==4">
          <p class="import">对筛选条件进行权重设置，便于查看每项条件在项目中的重要性。</p>
          <p class="notice">注：每个指标的权重值精度为0.0%，且权重比之和必须为100%</p>
           <el-table
              :data="tableData4"
              style="width: 100%" :cell-style="{background:'#f4f5f5'}" :header-cell-style="{background:'#f4f5f5'}">
              <el-table-column prop="first" label="一级条件" width="150"></el-table-column>
              <el-table-column prop="second" label="二级条件" width="200"></el-table-column>
              <el-table-column prop="set" label="设定权重">
                <template slot-scope="scope">
                   <el-input placeholder="输入占比" style="width:120px" v-model="scope.row.set" @input="oninput(scope)"></el-input>
                  <span>%</span>
                </template>
              </el-table-column>
          </el-table>
          <div class="total">合计：<span>{{total_percent}}%</span></div>
        </div>
        <el-button type="info" style="width: 80px; margin:20px 2%" @click="before()" v-if="num>1&&num<5">上一步</el-button>
        <el-button type="primary" style="width: 80px; margin:20px 2%" @click="next()" v-if="num<5" :plain="warning">下一步</el-button>
      </div>
    </div>
    <!-- 完成页面 -->
    <div class="five" v-if="num==5">
      <div class="fiveTop">
        <div class="message">
          <p>根据您的招商需求描述，大数据实时为您推荐以下招商项目</p>
          <p>点击跟进该企业，可在招商OA中实时掌握公司最新信息动态。</p>
        </div>
        <div class="selectBtn">
          <el-button type="primary">招商OA</el-button>
          <el-button type="primary">修改招商条件</el-button>
        </div>
      </div>
      <div>
        <el-table ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="所在地" width="120">
            <template slot-scope="scope">{{ scope.row.address }}</template>
          </el-table-column>
          <el-table-column prop="branchName" label="品牌名称"></el-table-column>
          <el-table-column prop="firmName" label="企业名称"></el-table-column>
          <el-table-column prop="time" label="注册时间"></el-table-column>
          <el-table-column prop="area" label="行业领域"></el-table-column>
          <el-table-column prop="turn" label="融资轮次"></el-table-column>
          <el-table-column prop="people" label="负责人"></el-table-column>
          <el-table-column prop="rate" label="当前匹配度"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="lookweight(scope.row)">查看权重比</el-button>
                <el-dialog title="" :visible.sync="dialogTableVisible">
                  <el-table :data="gridData">
                    <el-table-column property="first" label="一级条件" width="150"></el-table-column>
                    <el-table-column property="second" label="二级条件" width="200"></el-table-column>
                    <el-table-column property="set" label="设定权重"></el-table-column>
                    <el-table-column property="match" label="匹配度"></el-table-column>
                    <el-table-column property="describe" label="指标描述"></el-table-column>
                  </el-table>
                  <div class="nowMatch">当前匹配度:<span>{{weight}}</span></div>
                </el-dialog>
              <el-button
                size="mini"
                @click="follows(scope.row)">跟进</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          size="mini"
          style="margin: 15px 2% 15px 90%" v-if="this.looktotal!==1">批量跟进</el-button>
          <div class="block" v-if="this.looktotal!==1">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="1"
              :page-count="currentPage4"
              layout="total, prev, pager, next, jumper"
              :total="looktotal"
              style="margin:20px auto;text-align: center;">
            </el-pagination>
          </div>
      </div>
    </div>
    <el-dialog title="选择行业" :visible.sync="dialogTableVisible">
      <el-form ref="addForm" :model="addForm" label-width="100px">
        <el-radio-group v-model="addForm.industry_type" @change="changeCategory">
          <el-radio :label="1">创投行业分类</el-radio>
          <el-radio :label="2">国民经济行业分类</el-radio>
        </el-radio-group>
        <el-cascader
          v-if="addForm.industry_type==2"
          v-model="addForm.industry"
          ref="myCascader"
          :options="sort"
          :props="{ multiple: true, checkStrictly: true, value:'id',label:'title'}"
          :show-all-levels="false"
          style="display:block; width:50%; margin: 15px 0" ></el-cascader>
        <el-cascader
          v-else
          v-model="addForm.industry"
          ref="myCascader"
          :options="sort"
          :props="{ multiple: true, checkStrictly: true, value:'pkid',label:'title'}"
          :show-all-levels="false"
          style="display:block; width:50%; margin: 15px 0"
        ></el-cascader>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="work()">确 定</el-button>
      </div>
    </el-dialog>
     <el-dialog title="地区选择" :visible.sync="areaFormVisible">
      <el-form :model="form" style="width:80%; margin:auto">
        <div class="formControl">
          <el-radio-group v-model="apply_to_area_type">
            <el-radio
              :label="item.id"
              v-for="item in areaType"
              :key="item.id"
              @change="diyuChange()"
            >{{ item.title }}</el-radio>
          </el-radio-group>
        </div>
        <div class="item" v-if="apply_to_area_type == 2">
          <label for class="label"></label>
          <div class="formControl">
            <el-cascader
              ref="areavalue"
              :options="area"
              :props="props1"
              size="medium"
              v-model="areas"
            ></el-cascader>
          </div>
        </div>
        <div class="item" v-if="apply_to_area_type == 3">
          <label for class="label"></label>
          <div class="formControl">
            <el-cascader
            ref="areavalue"
            :options="yuanqu"
            :props="props2"
            size="medium"
            v-model="yq"></el-cascader>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="areaFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="areavalue()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="企业规模选择" :visible.sync="sizeFormVisible">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="">
          <el-select v-model="size" placeholder="请选择企业规模" size="medium">
          <el-option
            v-for="item in guimo"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sizeFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sizes()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="从业人数" :visible.sync="jobFormVisible">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="">
          <input type="text" name="" id="" placeholder="输入从业人数" v-model="people">
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jobFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="peoples()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="营收收入" :visible.sync="saleFormVisible">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="">
          <input type="text" name="" id="" placeholder="请输入营收收入" style="float:left" v-model="money"><span class="money" style="float:left">万元</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saleFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="totals()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="资产总额" :visible.sync="totle">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="" style="overflow:hidden">
          <input type="text" name="" id="" placeholder="请输入营收收入" style="float:left" v-model="total"><span class="money" style="float:left">万元</span>
        </el-form-item>
          <div class="msg">文字提示：我们为您选择大于等于所填资产总额的企业</div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="totle = false">取 消</el-button>
        <el-button type="primary" @click="totles()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择融资阶段" :visible.sync="select">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="" prop="stage_id">
          <el-select v-model="selected" placeholder="请选择" style="width:140px">
            <el-option
              v-for="item in stages"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="select = false">取 消</el-button>
        <el-button type="primary" @click="selects()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="企业荣誉" :visible.sync="credit">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-checkbox :label="item.title" v-for="(item, index) in labelsData" :key="index" @change="honer(item)" :checked="item.checked"></el-checkbox>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="credit = false">取 消</el-button>
        <el-button type="primary" @click="honerValue()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="所属级别" :visible.sync="grade">
      <el-form :model="form" style="width:80%; margin:auto">
        <el-form-item label="">
          <el-select placeholder="请选择"  v-model="form.level_id"  style="width:50%; height:45px; margin:auto">
            <el-option v-for="item in level_ids" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="grade = false">取 消</el-button>
        <el-button type="primary" @click="gradeValue()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { success, warning, error } from "@/utils/notification";
export default {
  name: 'findCompany',
  data() {
    return {
      total_percent: 0, // 第四部展示的条件清单，total_percent
      jobValueChecked: [], // 第三部展示的条件清单，行业
      num:1,
      radio: 3,
      radios:1,
      show:true,
      condition:[], //设置招商条件
      currentPage: 1,
      pages: 0,
      looktotal: 1, //完成页面页码
      pageCount: 0, //页码总数
      ipt:'',
      text:'',
      id: '',
      dialogFormVisible:false,//行业
      areaFormVisible:false,//地区选择
      sizeFormVisible:false, //规模选择
      jobFormVisible:false, //从业人数
      saleFormVisible:false, //营收收入
      totle:false, //资产总额
      select:false, //融资阶段
      credit:false, //企业荣誉
      grade:false, //所属级别
      undefineds: false, //自定义添加
      input: '', //自定义名称
      textarea1: '', //指标定义
      textarea2: '', //特色案例
      checked: true,// 多选框
      warning:false,
      industry: [
        {
          required: true,
          message: "请选择行业领域",
          trigger: "change"
        }
      ],
      jobValue:[], //已选行业值
      jobValues: [], //确定已选行业值
      areasValue:[], //已选地区值
      demands: [], //创建需求条件
      weights:[], //已选行业设置权重
      typearr:[],//选中行业内容
      child:[], //已选行业
      childNodes:[], //已选行业子集
      type:[], //选项框label
      addForm: { industry: [] }, //添加表单
      sort: [],
      dialogTableVisible:false,//查看权重比
      guimo:[],// 规模
      size: '', //已选企业规模
      people:'', //从业人数
      money: '', //营收收入
      total: '',//资产总额
      selected: '',// 融资轮次确定按钮
      apply_to_area_type: 1, //地域:1全国,2其他省市,3园区
      apply_to_area_type_array: [], //apply_to_area_type:省市区数组，3:园区数组，1:不传 
      customize: {}, //自定义名称
      areaType: [
        {
          id: 1,
          title: "全国"
        },
        {
          id: 2,
          title: "其他省市"
        },
        {
          id: 3,
          title: "园区"
        }
      ],
      area: [],
      yuanqu: [],
      areas: [],
      yq: [],
      stages: [], //融资轮次
      key:'', //企业荣誉
      honers: [], //获取企业荣誉
      grades:'', //所属级别
      labelsData: [], //标签
      props1: {
        multiple: true,
        checkStrictly: true,
        value: "id",
        label: "name"
      },
      props2: {
        multiple: true,
        value: "id",
        label: "name"
      },
      level_ids:[],//空间级别列表
      match_id: '', //查看权重比ID
      // 输入框内的值
      num1:'', //行业值
      num2:'', //地区值
      num3:'', //规模值
      num4:'', //收入值
      tableData: [],
      labelTit:[], //表格一级条件
      secondTit:[], //表格二级条件
      search: '',
      optionindex: [{
        value: '选项1',
        label: '本月新增'
      }, {
        value: '选项2',
        label: '本周新增'
      }, {
        value: '选项3',
        label: '本日新增'
      }],
      value: '',
      checkList: [], //多选框已选属性
        form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      tableData1: [],
      tableData4: [],
      multipleSelection: [],
      currentPage4: 0 ,//分页
      // 查看权重
      gridData: [],
      weight: '', //当前匹配度
      enterprise_ids: [], //跟进的匹配企业ID
      status: '',
      }
    },
    mounted() {
      this.getCondition();
      if (JSON.parse(localStorage.getItem("area"))) {
        this.area = JSON.parse(localStorage.getItem("area"));
      } else {
        this.getgetArea();
      };
      this.getgetOrgins();
      this.getGuimoData();
      // this.getDemands();
      this.getLabels(); //获取领域数据
      this.getLevels(); //获取所属级别
      this.getLists(3); //获取需求列表
    },
    methods: {
      // 获取是否正在追踪
      getRadio() { 
        this.getLists(this.radio) 
      },
      // 设置招商条件的checkbox切换
      businessConditionChange(item) {
        console.log(item, '快晒')
      },
      newFind() {
        this.show=!this.show
        this.num=1
      },
      jumpFind(data) {
        this.show=!this.show
        this.num=1
        this.id=data.id
        this.getdetails(); //获取招商需求详情
      },
      // 点击上一步
      before() {
        this.num--;
      },
      // 点击下一步
      next() {
        if(this.num == 1) {
          if(this.ipt==''||this.text=='') {
            warning('招商需求描述或名称不能为空...')
          }else {
            this.num++
          }
        } else {
          this.num++
        }  
        if(this.num==3) { 
          if(this.checkList.length==0) {
             warning('请至少设置一项招商条件')
             this.num=2
          }  
          for(var i=0; i<this.labelTit.length; i++) {
            this.tableData4.push(
              {
                first: '',
                second: '',
                set: '',
              }
            )
            this.tableData4[i].first=this.labelTit[i]
            this.tableData4[i].second=this.secondTit[i]
          }
          for(var i=0; i<this.typearr.length; i++) {
            this.demands.push({
              "category":"",
              "child":[],
              "type":''
            },)
            this.weights.push({
              "category":"",
              "value":''
            })
            this.demands[i].category=this.typearr[i]
            this.demands[i].child.push(this.childNodes[i])
            this.demands[i].type=this.type[i]
            this.weights[i].category=this.typearr[i]
          }
        }
        if(this.num==5) {
          for(var i=0; i<this.typearr.length; i++) {
            this.weights[i].value=this.tableData4[i].set/100
          }
          this.getDemands()
        }
      },
      // 查看
      look(data) {
        this.show=!this.show
        this.num=5
        this.id=data.id
        this.getmate()
      },
      change(data,type) {
        this.typearr.push(type)
        const dialogShowFlag = this.checkList.indexOf(this.condition[data].id) > -1
        if(data==0 && dialogShowFlag) {
          this.dialogTableVisible=true
          this.labelTit.push(this.condition[0].name)
        }
        if(data==1 && dialogShowFlag) {
          this.areaFormVisible=true
          this.labelTit.push(this.condition[1].name)
        }
        if(data==2 && dialogShowFlag) {
          this.sizeFormVisible=true
          this.labelTit.push(this.condition[2].name)
        }
        if(data==3 && dialogShowFlag) {
          this.jobFormVisible=true
          this.labelTit.push(this.condition[3].name)
        }
        if(data==4 && dialogShowFlag) {
          this.saleFormVisible=true
          this.labelTit.push(this.condition[4].name)
        }
        if(data==5 && dialogShowFlag) {
          this.totle=true
          this.labelTit.push(this.condition[5].name)
        }
        if(data==6 && dialogShowFlag) {
          this.select=true
          this.getstages(1);
          this.labelTit.push(this.condition[6].name)
        }
        if(data==7 && dialogShowFlag) {
          this.credit=true
          this.labelTit.push(this.condition[7].name)
        }
        if(data==8 && dialogShowFlag) {
          this.grade=true
          this.labelTit.push(this.condition[8].name)
        }
      },
       toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        console.log(val,'已选跟进')
        this.multipleSelection = val;
      },
      // 分页
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      // 选择分类领域
      changeCategory(id) {
        this.type.push(id)
        if(id==1) {
          this.getCategories("starts");
        }else {
          this.getCategories("economics");
        }
        this.addForm.industry = [];
      },
      //分类 - 创投和国民行业列表
      async getCategories(url) {
        const res = await this.$http.get(this.URL.adminUrl[url], {});
        this.sort = res.data.data;
      },
      // 行业分类点击确定
      work() {
        this.jobValueChecked = []
        this.jobValue = []
        this.sort.forEach(item => {
          this.addForm.industry.forEach(industry =>{
            if (industry.length === 1 && industry[0]=== item.id) {
              this.jobValue.push(item)
              this.jobValueChecked.push(item.id)
            }
          })
          item.children && item.children.forEach(item_child =>{
            this.addForm.industry.forEach(industry =>{
              if (industry.length > 1 && industry[1] === item_child.id) {
                this.jobValue.push(item_child)
                this.jobValueChecked.push(item_child.id)
              }
            })
          })
        })
        this.dialogTableVisible=false;
        this.jobValues=this.$refs['myCascader'].$el.innerText.split("\n")
        this.secondTit.push(this.jobValues)
        for(var i=0; i<this.$refs['myCascader'].checkedNodes.length; i++) {
          this.child.push(this.$refs['myCascader'].checkedNodes[i].data.id)
        }
        this.childNodes.push(this.child)
      },
      //获取地区
      getgetArea() {
        this.$http.get(this.URL.adminUrl.areaTree).then(res => {
          localStorage.setItem("area", JSON.stringify(res.data.data));
        });
      },
      //获取园区
      getgetOrgins() {
        this.$http.get(this.URL.adminUrl.search_organs).then(res => {
          this.yuanqu = res.data.data;
        });
      },
      diyuChange() {
        this.type.push(this.apply_to_area_type)
        this.apply_to_area_type_array = [];
      },
      //地区选择确定
      areavalue() {
        this.child=[];
        this.areaFormVisible=false;
        this.areasValue=this.$refs['areavalue'].$el.innerText.split("\n")
        for(var i=0; i<this.$refs['areavalue'].checkedNodes.length; i++) {
          this.child.push(this.$refs['areavalue'].checkedNodes[i].data.id)
        }
        this.childNodes.push(this.child)
        this.secondTit.push(this.areasValue)
      },
      //获取规模数据
      getGuimoData() {
        this.$http.get(this.URL.adminUrl.policy).then(res => {
          this.guimo = res.data.data;
        });
      },
      // 企业规模确定
      sizes(){
        this.sizeFormVisible = false
        this.childNodes.push(this.size)
        this.size=1?'微型企业':2?'小型企业':3?'中型企业':4?'中小型企业':'规模以上企业';
        this.secondTit.push(this.size)
      },
      // 从业人数
      peoples() {
        this.jobFormVisible=false;
        this.secondTit.push(this.people)
        this.childNodes.push(this.people)
      },
      // 营业收入
      totals() {
        this.saleFormVisible = false;
        this.secondTit.push(this.money+'万元')
        this.childNodes.push(this.money)
      },
      // 资产总额
      totles() {
        this.totle = false;
        this.secondTit.push(this.total+'万元')
        this.childNodes.push(this.total)
      },
      // 融资轮次确定按钮
      selects() {
        this.select=false;
        this.childNodes.push(this.selected)
        this.selected=1?'天使轮':2?'种子轮':3?'A轮':4?'B轮':5?'C轮':'D轮';
        this.secondTit.push(this.selected)
      },
      //融资轮次
      async getstages(page) {
        const res = await this.$http.get(this.URL.adminUrl.stagesList, {
          params: {
            page
          }
        });
        this.stages = res.data.data;
      },
      // 企业荣誉
      getLabels() {
        this.$http.get(this.URL.adminUrl.labels).then(res => {
          this.labelsData = res.data.data;
        });
      },
      honer(checked) {
        this.honers.push(checked)
      },
      // 企业荣誉确定按钮
      honerValue() {
        this.child=[]
        this.credit = false
        for(var i=0; i<this.honers.length; i++) {
          this.secondTit.push(this.honers[i].title)
          this.child.push(this.honer[i].id)
        }
        this.childNodes.push(this.child)
      },
      // 获取所属级别
      getLevels(){
				this.$http.get(this.URL.adminUrl.space_level)
        .then(res=>{
          this.level_ids=res.data.data
        })
      },
      // 所属级别确定
      gradeValue() {
        this.grade = false
        this.childNodes.push(this.grades)
        this.grades=1?'国家级':2?'省市级':3?'地市级':4?'区县级':'其他级';
        this.secondTit.push(this.grades)
      },
      // 自定义招商分类确定
      sure() {
        this.checkList.push(9)
        this.undefineds = false
        if(this.input==''||this.textarea1=='') {
          this.undefineds = true
           warning('指标名称和指标定义不能为空...')
        }else {
          this.customize.name = this.input;
          this.customize.desc = this.textarea1;
          this.labelTit.push(this.customize.name)
          this.secondTit.push(this.customize.desc)
        }
        
        this.$http.post(this.URL.undefineds, {
          organ_id: sessionStorage.organizationId - 0,
          name: this.input,
          explain: this.textarea1,
          case: this.textarea2,
        }).then(res=> {
          console.log(res,"自定义招商分类返回数据")
        })
      },
      // 设置招商条件
      getCondition() {
        this.$http.get(this.URL.sort,{
          params:{
            organ_id: sessionStorage.organizationId
          }}).then(res=> {
            this.condition=res.data.data
        })
      },
      // 创建招商需求
      getDemands() {
        this.$http.post(this.URL.demands, {
          organ_id: sessionStorage.organizationId,
          title: this.ipt,
          intro: this.text,
          demand: this.demands,
          weight: this.weights
        }).then(res=> {
          console.log(res)
        })
      },
      // 获取需求列表
      getLists(num) {
        this.$http.get(this.URL.lists,{
          params: {
            organ_id: sessionStorage.organizationId
          }
        }).then(res=> {  
          // 获取全部
          if(num==6) {
            this.tableData=[]
            var newTable = [];
            newTable = res.data.data.data.filter(num=>num.status==1) 
            for(var i=0; i<newTable.length; i++) { 
              this.tableData.push({
                title: '',
                intro: '',
                project_count: '',
                new_count:'',
                data: '',
                id:'',
                status: ''
              })
              this.tableData[i].title=newTable[i].title
              this.tableData[i].intro=newTable[i].intro
              this.tableData[i].project_count=newTable[i].project_count
              this.tableData[i].new_count=newTable[i].new_count
              this.tableData[i].data=newTable[i].created_at.date
              this.tableData[i].id=newTable[i].id
              this.tableData[i].status=newTable[i].status
            }    
            this.pages=this.tableData.length
            this.pageCount=Math.ceil(this.pages/10)
          }  
          if(num==9) {
            this.tableData=[]
            var newTab = [];
            newTab = res.data.data.data.filter(num=>num.status==0)
            for(var i=0; i<newTab.length; i++) { 
              this.tableData.push({
                title: '',
                intro: '',
                project_count: '',
                new_count:'',
                data: '',
                id:'',
                status: ''
              })  
              for(var i=0; i<this.tableData.length; i++) {
                this.tableData[i].title=newTab[i].title
                this.tableData[i].intro=newTab[i].intro
                this.tableData[i].project_count=newTab[i].project_count
                this.tableData[i].new_count=newTab[i].new_count
                this.tableData[i].data=newTab[i].created_at.date
                this.tableData[i].id=newTab[i].id
                this.tableData[i].status=newTab[i].status
              }  
            }
            this.pages=this.tableData.length
            this.pageCount=Math.ceil(this.pages/10)
          }
          if(num==3) {
            this.tableData=[]
            for(var i=0; i<res.data.data.data.length; i++) {   
              this.tableData.push({
                title: '',
                intro: '',
                project_count: '',
                new_count:'',
                data: '',
                id:'',
                status: ''
              })   
              this.tableData[i].title=res.data.data.data[i].title
              this.tableData[i].intro=res.data.data.data[i].intro
              this.tableData[i].project_count=res.data.data.data[i].project_count
              this.tableData[i].new_count=res.data.data.data[i].new_count
              this.tableData[i].data=res.data.data.data[i].created_at.date
              this.tableData[i].id=res.data.data.data[i].id
              this.tableData[i].status=res.data.data.data[i].status   
            }
            this.pages=this.tableData.length
            this.pageCount=Math.ceil(this.pages/10)
          }
        })
      },
      // 招商需求启用禁用
      handleDelete(row) {
        console.log(row)
				let txt = row.status ? '禁用' : '启用';
				this.status=row.id
				this.$confirm('是否' + txt, '提示', {
				}).then(() => {
					this.$http.put(this.URL.lists+'/'+this.status+this.URL.status, {
						from: 'manager',
						organ_id: sessionStorage.organizationId,
						status: row.status == 1 ? 0 : 1
					}).then((res) => {
						row.status = row.status == 1 ? 0 : 1;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
      },
      // 获取招商需求详情
      getdetails() {
        this.$http.get(this.URL.details+'/'+this.id, {
          params: {
            organ_id: sessionStorage.organizationId
          }
        }).then(res=> {
          this.ipt=res.data.data.title;
          this.text=res.data.data.intro;
          for(var i=0; i<res.data.data.demand.length; i++) {
            var arr=[]
            arr.push(res.data.data.demand[i].category='inindystry'?1:'area'?2:'scale'?3:'member_count'?4:'income'?5:'total_money'?6:'stage'?7:'honor'?8:'grade')
          }
          this.checkList=arr
        })
      },
      // 获取匹配企业列表
      getmate() {
        this.$http.get(this.URL.matelists,{
          params: {
            organ_id: sessionStorage.organizationId,
            demand_id: this.id
          }
        }).then(res=> {
          for(var i=0; i<res.data.data.data.length; i++) {
            this.tableData1.push({
              address: '',
              branchName: '',
              firmName: '',
              time:'',
              area:'',
              turn:'',
              people:'',
              rate:''
            })
            this.tableData1[i].address=res.data.data.data[i].address
            this.tableData1[i].branchName=res.data.data[i].brand_name
            this.tableData1[i].firmName=res.data.data[i].company_name
            this.tableData1[i].time=res.data.data[i].create_time
            this.tableData1[i].area=res.data.data[i].industry
            this.tableData1[i].turn=res.data.data[i].stag_name
            this.tableData1[i].people=res.data.data[i].user_name
            this.tableData1[i].rate=res.data.data[i].weight
          }
          this.currentPage4=res.data.data.meta.total
          if(this.currentPage4==0) {
            this.looktotal=1
          }else {
            this.looktotal=Math.ceil(this.currentPage4/10)
          }
        })
      },
      // 查看权重比
      lookweight(data) {
        this.dialogTableVisible=true
        this.match_id=data.id
        this.getweight() //获取权重比详情
      },
      // 获取权重比详情
      getweight() {
        this.$http.get(this.URL.weight, {
          params: {
            organ_id: sessionStorage.organizationId,
            match_id: this.match_id
          }
        }).then(res=> {
          for(var i=0; i<res.data.data.data.length; i++) {
            this.gridData.push({
              first: '',
              second: '',
              set: '30%',
              match:'30%',
              describe:'命中'
            })
            this.gridData[i].first=res.data.data.data[i].one_grade
            this.gridData[i].second=res.data.data.data[i].two_grade
            this.gridData[i].set=res.data.data.data[i].custom_weight+'%'
            this.gridData[i].match=res.data.data.data[i].match_weight+'%'
            this.gridData[i].describe=res.data.data.data[i].hit?false:'未命中'?true:'命中'
          }
          this.weight=Number(res.data.data.weight*100)+'%'
        })
      },
      // 点击跟进
      follows(data) {
        this.enterprise_ids.push(data.id)
        this.getfollow()
      },
      // 匹配企业跟进
      getfollow() {
        this.$http.post(this.URL.follow, {
          organ_id: sessionStorage.organizationId,
          enterprise_ids: this.enterprise_ids
        }).then(res=> {
          console.log(res,"follow")
        })
      },
      // 小数点后一位
      oninput(first) { 
        this.total_percent = 0
        let setNumber = this.tableData4[first.$index].set
        this.tableData4.forEach(item => {
          this.total_percent += item.set - 0
        })
        if (this.total_percent > 100) {
          warning('您当前输入权重之和已超过100%，请更改...')
          return false
        }
      },
    }
};
</script>
<style scoped>
h3 {
  left: 206px;
  top: 86px;
  width: 104px;
  height: 38px;
  color: rgba(16, 16, 16, 1);
  font-size: 26px;
  text-align: left;
  font-family: "SourceHanSansSC-bold";
}
.analysis {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  width: 765px;
  height: 318px;
  line-height: 20px;
  border-radius: 8px;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  margin: 40px auto;
  text-align: center;
  font-family: "Roboto";
  border: 1px solid rgba(187, 187, 187, 1);
}
.cardContent {
  width: 601px;
  height: 72px;
  line-height: 36px;
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  margin: 65px auto;
  text-align: left;
  font-family: "SourceHanSansSC-regular";
}
.demo {
  color: #101010;
  font-size: 20px;
  margin: 10px 0 30px;
}
.el-radio {
  font-size: 20px !important;
}
.cardFooter {
  margin-top: 30px;
}
.ipt input, .ipt textarea {
  width: 70%;
}
.ipt span {
  float: left;
  height: 55px;
  line-height: 55px;
  margin-left: 20px;
}
/* 自定义弹窗 */
.text {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ipt span {
  width: 20%;
  color: #101010;
  font-size: 18px;
}
.ipt .warn {
  height: 55px;
  line-height: 55px;
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
input, textarea {
  outline: none;
  border: 1px solid #bbb;
  width: 460px;
}
input {
  height: 55px;
  margin-bottom: 20px;
}
textarea {
  resize: none;
}
/* 描述招商需求 */
.first {
  display: flex;
  align-items: center;
  justify-content: center;
}
.name, .second, .three, .four {
  width: 720px;
  height: auto;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 27px 28px;
  box-sizing: border-box;
}
.name p, .second p {
  width: 120px;
  height: 63px;
  font-size: 20px;
  color: #101010;
}
.money {
  display: inline-block;
  width: 80px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  border: 1px solid #ccc;
  border-left: none;
}
/* 文字提示 */
.msg {
  color: red;
  font-size: 16px;
}
/* 第三步 */
.threeTit {
  color: #4a91ec;
  font-size: 18px;
}
.work {
  width: 100%;
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.work span {
  font-size: 18px;
  color: #101010;
}
.selectwork {
  border: 1px solid #bbb;
  border-radius: 8px;
  height: 85px;
  width: 85%;
  padding: 20px;
  box-sizing: border-box;
}
.sort {
  display: flex;
  color: #101010;
  font-size: 16px;
}
.sort p {
  margin-left: 15px;
}
/* 第四步 */
.import {
  color: #4a91ec;
  font-size: 18px;
  margin: 10px 0;
}
.notice {
  color: red;
  font-size: 18px;
  margin-bottom: 15px;
}
/* 第五步 */
.fiveTop {
  width: 100%;
  margin: 20px auto;
  overflow: hidden;
}
.message {
  float: left;
  color: #4a91ec;
  font-size: 18px;
}
.message p {
  margin-bottom: 15px;
}
.selectBtn {
  float: right;
}
/* 弹窗当前匹配度 */
.nowMatch, .total {
  color: #101010;
  font-size: 16px;
  margin: 15px 20% 15px 60%;
}
</style>
